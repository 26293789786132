.ScrollAreaRoot {
  overflow: hidden;
  height: 100%;
}

.ScrollAreaViewport {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.ScrollAreaScrollbar {
  display: flex;
  user-select: none;
  touch-action: none;
  padding: 2px;
  background: #4b567d;
  border-radius: 10px;
  margin-right: 20px;
  transition: background 160ms ease-out;
}

.ScrollAreaScrollbar[data-orientation='horizontal'] {
  flex-direction: column;
  height: 10px;
}

.ScrollAreaThumb {
  flex: 1;
  background: #5493f7;
  border-radius: 10px;
  position: relative;
}
.ScrollAreaThumb::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  min-width: 44px;
  min-height: 44px;
}
