.apexcharts-canvas {
  background-image: url('src/img/grid-long.png');
  background-size: auto;
  background-repeat: no-repeat;
  background-position-x: inherit;
  background-position-y: 0px;
  border-radius: 7.82379px;
}

.tooltip-style {
  text-align: center;
  padding: 6px 10px;
  min-width: 112px;
  border-radius: 125px;
  font-size: 16px;
  font-family: sans-serif;
  background-color: #1f1f2d;
  color: #fff;
}

.tooltip-style__supply {
  color: #5493f7;
}
.tooltip-style__borrow {
  color: #53cbc8;
}
