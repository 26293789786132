#swing {
  width: 100%;
  /* Primary color available in all versions */
  --color-primary: 84 147 247;

  /* Base background color  */
  --background: 240 21% 26%;

  /* Base text color */
  --foreground: 0, 0%, 100%;

  /* Border color */
  --border: 227 25% 39%;

  /* Subtext color */
  --muted: 240 6% 68%;

  /* Hover/focus background color */
  --accent: 217 91% 65%;

  /* Popover background color */
  --popover: 236 37% 14%;

  /* Popover text color */
  --popover-foreground: 0, 0%, 100%;

  /* Secondary background color */
  --secondary: 236 37% 14%;
  /* Secondary text color */
  --secondary-foreground: 0, 0%, 100%;

  /* Hover/focus background color */
  --accent: 217 91% 65%;

  /* Hover/focus text color */
  --accent-foreground: 0, 0%, 100%;
}

#swing input::-webkit-outer-spin-button,
#swing input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#swing input[type='number'] {
  -moz-appearance: textfield;
}
